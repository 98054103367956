import Vue from 'vue';
import Vuex from 'vuex';

import inquery from './inquery';
import issuing from './issuing';
import senbadges from './senbadges';
import notice from './notice';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        inquery,
        issuing,
        senbadges,
        notice,
    }
});
