// import axios from 'axios';
import api from '@/api';
import {
//
} from './mutation-types';

export default {
    // eslint-disable-next-line no-unused-vars
    issueBadge ({ commit }, issue) {
        console.log('action! issue', issue);
        // https://lecos.co.kr/api/v1/OpenBadges
        return api.post('/api/v1/OpenBadges', issue, {
            headers: {
                'Accept-Language': issue.lang
            },
        });
    },
};
